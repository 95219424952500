import React from 'react';
import { useContext } from '../helpers/react-hooks';
import { Grid } from '@material-ui/core';
import { RootStoreContext } from '../App';
import {
  applyGoogleMapsControls,
  loadGoogleMapsScript
} from '../utils/googleMaps';
import { useObserver } from 'mobx-react';
import './ShopMap.css';

export default function ShopMap() {
  const root = useContext(RootStoreContext);

  React.useEffect(() => {
    if (!root.googleMapsLoaded && !root.googleMapsLoading) {
      loadGoogleMapsScript();
    } else if (root.googleMapsLoaded) {
      applyGoogleMapsControls();
    }
  });

  return useObserver(() => (
    <Grid container spacing={1}>
      {root.googleMapsLoaded ? (
        <Grid item xs={12}>
          <div id="shop-map"></div>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  ));
}
