import React from 'react';
import { useContext } from '../helpers/react-hooks';
import { useObserver } from 'mobx-react';
import { FormGroup, Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Form,
  FieldGroup,
  CheckboxGroup,
  Checkbox,
  Notification
} from '@lmig/lmds-react';
import { RootStoreContext } from '../App';
import localizedStrings from '../constants/localizedStrings';
import { PART_OPTIONS } from '../constants/constants';

const { SUPPLEMENT_TYPE } = localizedStrings;

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#28A3AF',
        border: 'solid 1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#28A3AF',
        border: 'solid 2px'
      }
    }
  }
})(TextField);

export default function SupplementType() {
  const store = useContext(RootStoreContext).supplementTypeStore;

  function setChecked(e) {
    store.set(e.target.name, e.target.checked);
  }

  function setValue(e) {
    store.set(e.target.name, e.target.value);
  }

  function highlightOnError(error) {
    return error ? 'negative' : 'informative';
  }

  function alertOnError(error) {
    return error ? 'Important' : undefined;
  }

  function styleOnError(errorType, type) {
    if (errorType) {
      return 'error-Card';
    } else if (type.value) {
      return 'selected-Card';
    } else {
      return 'simple-Card';
    }
  }

  return useObserver(() => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form>
          <Grid>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('noSelectionError')}
              messagesRole="status"
            >
              <Card className="simple-Card">
                <CheckboxGroup id="typeDamage" onChange={(e) => setChecked(e)}>
                  <Checkbox
                    labelVisual="Hidden / Additional Damage"
                    name="typeDamage"
                    checked={store.data.typeDamage.value}
                  />
                </CheckboxGroup>
              </Card>
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <Card className="simple-Card">
              <CheckboxGroup id="typeLabor" onChange={(e) => setChecked(e)}>
                <Checkbox
                  labelVisual="Change in Labor Hours"
                  name="typeLabor"
                  checked={store.data.typeLabor.value}
                />
              </CheckboxGroup>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('noPartsSelectionError')}
              messagesRole="status"
            >
              <Card
                className={styleOnError(
                  store.noPartsSelectionError,
                  store.data.typeParts
                )}
              >
                <CheckboxGroup id="typeParts" onChange={(e) => setChecked(e)}>
                  <Checkbox
                    labelVisual="Additional / Changed Parts"
                    name="typeParts"
                    checked={store.data.typeParts.value}
                  />
                  {store.data.typeParts.value ? (
                    <div>
                      <hr />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Notification
                            highlightType={highlightOnError(
                              store.noPartsSelectionError
                            )}
                            alert={alertOnError(store.noPartsSelectionError)}
                          >
                            {SUPPLEMENT_TYPE.PARTS_CARD}
                          </Notification>
                          <CheckboxGroup
                            id="typePartsOptions"
                            onChange={(e) => setChecked(e)}
                          >
                            {PART_OPTIONS.map(
                              ({ partOption, optionText }, index) => (
                                <Checkbox
                                  key={index}
                                  labelVisual={optionText}
                                  name={partOption}
                                  checked={store.data[partOption].value}
                                />
                              )
                            )}
                          </CheckboxGroup>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </CheckboxGroup>
              </Card>
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messagesRole="status"
            >
              <Card className={styleOnError(false, store.data.typeSubletInvoice)}>
                <CheckboxGroup
                  id="typeSubletInvoice"
                  onChange={(e) => setChecked(e)}>
                  <Checkbox
                    labelVisual="Sublet Invoice"
                    name="typeSubletInvoice"
                    checked={store.data.typeSubletInvoice.value}
                  />
                  {store.data.typeSubletInvoice.value ? (
                    <div>
                      <hr />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Notification
                            highlightType="informative"
                          >
                            {SUPPLEMENT_TYPE.SUBLET_INVOICE_CARD_BEG}
                            <b>{SUPPLEMENT_TYPE.SUBLET_INVOICE_CARD_BOLD}</b>
                            {SUPPLEMENT_TYPE.SUBLET_INVOICE_CARD_END}
                          </Notification>
                          <br />
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </CheckboxGroup>
              </Card>
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('noOtherReasonError')}
              messagesRole="status"
            >
              <Card
                className={styleOnError(
                  store.noOtherReasonError,
                  store.data.typeOther
                )}
              >
                <CheckboxGroup id="typeOther" onChange={(e) => setChecked(e)}>
                  <Checkbox
                    labelVisual="Other (Freeform)"
                    name="typeOther"
                    checked={store.data.typeOther.value}
                  />
                  {store.data.typeOther.value ? (
                    <div>
                      <hr />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Notification
                            highlightType={highlightOnError(
                              store.noOtherReasonError
                            )}
                            alert={alertOnError(store.noOtherReasonError)}
                          >
                            {SUPPLEMENT_TYPE.OTHER_CARD}
                          </Notification>
                          <br />
                          <FormGroup>
                            <StyledTextField
                              id="otherReason"
                              fullWidth
                              multiline
                              rows="3"
                              variant="outlined"
                              name="otherReason"
                              value={store.data.otherReason.value}
                              inputProps={{ maxLength: 200 }}
                              onChange={(e) => setValue(e)}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </CheckboxGroup>
              </Card>
            </FieldGroup>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  ));
}
