import axios from 'axios';
import getEnv from './getEnv';

export function postSuppData(store) {
  return new Promise((resolve, reject) => {
    const shopDetailsStore = store.shopDetailsStore;
    const supplementUploadStore = store.supplementUploadStore;
    const supplementTypeStore = store.supplementTypeStore;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        type: 'formData'
      }
    };

    const data = new FormData();
    data.append('supplementId', store.id);
    Object.keys(shopDetailsStore.data).forEach((key) => {
      data.append(key, shopDetailsStore.data[key].value);
    });

    data.append('supplementTypeOption', supplementTypeStore.supplementTypeOption);
    data.append('supplementPartInfo', supplementTypeStore.supplementPartInfo);
    data.append('supplementTypeOther', supplementTypeStore.data.otherReason.value);

    for (const file of supplementUploadStore.files) {
      const fileBlob = dataURItoBlob(file.rawFile);
      data.append(file.rawFile.category, fileBlob, file.rawFile.name);
    }

    axios
      .post(`${getEnv('API')}/righttouch`, data, config)
      .then((response) => (response.status === 200 ? resolve() : reject()))
      .catch((error) => reject(error));
  });
}

function dataURItoBlob(rawFile) {
  const binary = atob(rawFile.src.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: rawFile.type });
}
