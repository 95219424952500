import React, { useContext, useState } from 'react';
import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link
} from '@material-ui/core';
import {
  CardGrid,
  Card,
  List,
  ListItem,
  IconSuccessAlert,
  IconGuaranteedAutoRepair,
  IconDocument,
  IconDocumentMulti,
  Notification
} from '@lmig/lmds-react';
import { RootStoreContext } from '../App';
import { PART_OPTIONS } from '../constants/constants';

const pdfImage = require('../helpers/supplement.png');

export default function Review() {
  const rootStore = useContext(RootStoreContext);
  const shopDetailsStore = rootStore.shopDetailsStore;
  const supplementTypeStore = rootStore.supplementTypeStore;
  const supplementUploadStore = rootStore.supplementUploadStore;
  const [isShown, setIsShown] = useState(false);
  const toggleOtherView = () => setIsShown(!isShown);

  function ImageContent(image) {
    const extension = image.rawFile.name
      .split('.')
      .pop()
      .toLowerCase();
    const src = extension === 'pdf' ? pdfImage : image.rawFile.src;
    return <img style={{ top: 'auto', left: 'auto' }} alt="X" src={src} />;
  }

  function updateOtherReason(otherReasonValue) {
    if (!isShown) {
      return (
        <span>
          Other &#8212; {String(otherReasonValue).substr(0, 29)}
          {String(otherReasonValue).length >= 30 && (
            <span>
              &#8230;
              <div id="toggleOther">
                <Link id="toggleView" onClick={toggleOtherView}>
                  View more &#9660;
                </Link>
              </div>
            </span>
          )}
        </span>
      );
    } else {
      return (
        <span>
          Other &#8212; {otherReasonValue}
          <div id="toggleOther">
            <Link id="toggleView" onClick={toggleOtherView}>
              View less &#9650;
            </Link>
          </div>
        </span>
      );
    }
  }

  return (
    <Grid container spacing={3}>
      <br />
      <Grid item xs={12} zeroMinWidth>
        <Notification
          alert="If any amendments are required please navigate back to relevant page"
          highlightType="informative"
        />
      </Grid>
      <CardGrid id="padCardGrid">
        <Grid container spacing={2}>
          <Grid item xs={12} zeroMinWidth>
            <Card>
              <Grid>
                <IconGuaranteedAutoRepair title="Shop details" />
                &nbsp;&nbsp;Shop details
                <hr style={{ padding: 'absolute' }} />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={5} zeroMinWidth>
                  Claim number:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.claimNumber.value}</strong>
                </Grid>
                {!!shopDetailsStore.data.taxId.value &&
                <><Grid item xs={5} zeroMinWidth>
                  Federal tax ID:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.taxId.value}</strong>
                </Grid></>}
                <Grid item xs={5} zeroMinWidth>
                  Shop name:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopName.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Address:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopAddress1.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  City:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopAddress2.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  State:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopState.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Zip code:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopZipCode.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Telephone:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopTelephone.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Email:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopEmail.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Contact person:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>{shopDetailsStore.data.shopContact.value}</strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Completion date:
                </Grid>
                <Grid item xs={7} zeroMinWidth>
                  <strong>
                    {shopDetailsStore.data.estimatedCompletionDate.value}
                  </strong>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  Direction to Pay:
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  <strong>
                    {shopDetailsStore.data.directionToPay.value}
                  </strong>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Card>
              <Grid>
                <IconDocument title="Supplement type(s)" />
                &nbsp;&nbsp;Supplement type(s)
                <hr />
              </Grid>
              <List icon={<IconSuccessAlert color="positive" />}>
                {supplementTypeStore.data.typeDamage.value && (
                  <ListItem>Hidden / Additional Damage</ListItem>
                )}
                {supplementTypeStore.data.typeLabor.value && (
                  <ListItem>Change in Labor Hours</ListItem>
                )}
                {supplementTypeStore.data.typeParts.value && (
                  <ListItem>
                    Additional / Changed Parts
                    <List icon={<IconSuccessAlert color="positive" />}>
                      {PART_OPTIONS.map(
                        ({ partOption, optionText }) =>
                          supplementTypeStore.data[partOption].value && (
                            <ListItem key={partOption}>{optionText}</ListItem>
                          )
                      )}
                    </List>
                  </ListItem>
                )}
                {supplementTypeStore.data.typeSubletInvoice.value && (
                  <ListItem>Sublet Invoice</ListItem>
                )}
                {supplementTypeStore.data.typeOther.value && (
                  <ListItem>
                    {updateOtherReason(
                      supplementTypeStore.data.otherReason.value
                    )}
                  </ListItem>
                )}
              </List>
            </Card>
          </Grid>
          <Grid item xs={12} zeroMinWidth>
            <Card>
              <Grid>
                <IconDocumentMulti title="Documents and images" />
                &nbsp;&nbsp;Documents and images
                <hr />
              </Grid>
              <Grid item xs={12} zeroMinWidth>
                <ImageList rowHeight={160} cols={5}>
                  {supplementUploadStore.files.map((file, i) => (
                    <ImageListItem key={`${file.id} - ${i}`}>
                      {ImageContent(file)}
                      <ImageListItemBar title={file.rawFile.name} />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </CardGrid>
      <Grid item xs={12} zeroMinWidth>
        <Notification
          alert="Press Submit to send all information to your adjuster"
          highlightType="informative"
        />
      </Grid>
    </Grid>
  );
}
