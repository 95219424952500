import React from 'react';
import { useContext } from '../helpers/react-hooks';
import { useObserver } from 'mobx-react';
import { RootStoreContext } from '../App';
import { Grid, Typography } from '@material-ui/core';
import {
  AlphanumericInput,
  GridRow,
  GridCol,
  Notification,
  Heading,
  Form,
  FieldGroup,
  Select,
  SelectOption
} from '@lmig/lmds-react';
import ShopLookup from './ShopMap';
import { STATE_LIST, DIRECTION_TO_PAY_OPTIONS } from '../constants/constants';

// Returns Claim Number, Requested Supplement Amount, Estimated Completion Date
function ClaimNumberDetailsForm() {
  const store = useContext(RootStoreContext).shopDetailsStore;
  function set(e) {
    store.set(e.target.name, e.target.value);
  }
  // When 'length' ammount of characters have been input, append expected dash as next character
  function appendDashAfter(length, e) {
    if (e.target.value.length === length) {
      store.data[e.target.name].value = `${e.target.value}-`;
    }
  }

  // Append cents to requested supplement amount if not added by user
  function appendCents(e) {
    set(e);
    if (store.data.dollarAmount.value !== '') {
      store.data.dollarAmount.value = `${parseFloat(e.target.value).toFixed(
        2
      )}`;
    }
  }

  return useObserver(() => (
    <Grid>
      <GridRow justify="center">
        <GridCol base={11}>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('claimNumber')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Claim number"
                name="claimNumber"
                id="claimNumber"
                placeholder="XXXXXXXXX-XXXX"
                value={store.data.claimNumber.value}
                filter={/^(\d{9}-\d{0,4})$|^(\d{1,9})$/}
                onChange={(e) => appendDashAfter(9, e)}
                onBlur={(e) => set(e)}
                highlightType={store.data.claimNumber.error ? 'error' : null}
              />
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('dollarAmount')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Requested supplement amount ($)"
                name="dollarAmount"
                id="dollarAmount"
                placeholder="0.00"
                value={store.data.dollarAmount.value}
                filter={/^(\d|[1-9]\d{1,5})(\.\d{0,2})?$/}
                onBlur={(e) => appendCents(e)}
                highlightType={store.data.dollarAmount.error ? 'error' : null}
              />
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('estimatedCompletionDate')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Estimated completion date"
                name="estimatedCompletionDate"
                mask="date"
                id="estimatedCompletionDate"
                value={store.data.estimatedCompletionDate.value}
                onBlur={(e) => set(e)}
                highlightType={
                  store.data.estimatedCompletionDate.error ? 'error' : null
                }
              />
            </FieldGroup>
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all">
              <Select
                labelVisual="Do you have a Direction to Pay from the customer?"
                name="directionToPay"
                id="directionToPay"
                placeholderOption="Direction to Pay"
                value={store.data.directionToPay.value}
                onChange={(e) => set(e)}
              >
                {DIRECTION_TO_PAY_OPTIONS.map(({ value }, index) => (
                  <SelectOption key={index} value={value}>
                    {value}
                  </SelectOption>
                ))}
              </Select>
            </FieldGroup>
          </Grid>
          <Heading type="h4" align="center">
            What are your contact details
          </Heading>
          <FieldGroup
            isFull
            disableMargins="all"
            messages={store.setErrorMsg('shopEmail')}
            messagesRole="status"
          >
            <AlphanumericInput
              labelVisual="Email address"
              name="shopEmail"
              type="email"
              id="shopEmail"
              value={store.data.shopEmail.value}
              onBlur={(e) => set(e)}
              highlightType={store.data.shopEmail.error ? 'error' : null}
            />
          </FieldGroup>
          <FieldGroup
            isFull
            disableMargins="all"
            messages={store.setErrorMsg('shopContact')}
            messagesRole="status"
          >
            <AlphanumericInput
              labelVisual="Contact name"
              name="shopContact"
              id="shopContact"
              value={store.data.shopContact.value}
              filter={/^[a-z ,.'-]+$/i}
              onBlur={(e) => set(e)}
              highlightType={store.data.shopContact.error ? 'error' : null}
            />
          </FieldGroup>
          <Heading type="h4" align="center">
            What is your shop Federal Tax ID?
          </Heading>
          <FieldGroup
            isFull
            disableMargins="all"
            messages={store.setErrorMsg('taxId')}
            messagesRole="status"
          >
            <AlphanumericInput
              labelVisual="Federal Tax ID"
              name="taxId"
              type="text"
              id="taxId"
              placeholder="XX-XXXXXXX"
              value={store.data.taxId.value}
              filter={/^\d{0,2}$|^(\d{2}-\d{0,7})$/}
              onChange={(e) => appendDashAfter(2, e)}
              onBlur={(e) => set(e)}
              highlightType={store.data.taxId.error ? 'error' : null}
            />
          </FieldGroup>
        </GridCol>
      </GridRow>
    </Grid>
  ));
}

// Returns Address details including Shop Name, Adress line 1 & 2, State, ZipCode & Phone
function AddressDetailsForm() {
  const store = useContext(RootStoreContext).shopDetailsStore;

  function set(e) {
    store.set(e.target.name, e.target.value);
  }

  return useObserver(() => (
    <Grid>
      <GridRow justify="center">
        <GridCol base={11}>
          <Grid item xs={12}>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopName')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Shop name"
                name="shopName"
                id="shopName"
                value={store.data.shopName.value}
                onBlur={(e) => set(e)}
                highlightType={store.data.shopName.error ? 'error' : null}
              />
            </FieldGroup>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopAddress1')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Address" // isMandatory
                name="shopAddress1"
                id="shopAddress1"
                value={store.data.shopAddress1.value}
                onBlur={(e) => set(e)}
                highlightType={store.data.shopAddress1.error ? 'error' : null}
              />
            </FieldGroup>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopAddress2')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="City" // isMandatory
                name="shopAddress2"
                id="shopAddress2"
                value={store.data.shopAddress2.value}
                onBlur={(e) => set(e)}
                highlightType={store.data.shopAddress2.error ? 'error' : null}
              />
            </FieldGroup>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopState')}
              messagesRole="status"
            >
              <Select
                labelVisual="State"
                name="shopState"
                id="shopState"
                placeholderOption="State"
                value={store.data.shopState.value}
                onChange={(e) => set(e)}
                highlightType={store.data.shopState.error ? 'error' : null}
                isRequired
              >
                {STATE_LIST.map(({ code, state }, index) => (
                  <SelectOption key={index} value={code}>
                    {code} - {state}
                  </SelectOption>
                ))}
              </Select>
            </FieldGroup>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopZipCode')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Zip code"
                name="shopZipCode"
                id="shopZipCode"
                value={store.data.shopZipCode.value}
                filter={/^\d{0,5}$/}
                onBlur={(e) => set(e)}
                highlightType={store.data.shopZipCode.error ? 'error' : null}
              />
            </FieldGroup>
            <FieldGroup
              isFull
              disableMargins="all"
              messages={store.setErrorMsg('shopTelephone')}
              messagesRole="status"
            >
              <AlphanumericInput
                labelVisual="Telephone"
                name="shopTelephone"
                id="shopTelephone"
                type="tel"
                mask="phone"
                value={store.data.shopTelephone.value}
                onBlur={(e) => set(e)}
                highlightType={store.data.shopTelephone.error ? 'error' : null}
              />
            </FieldGroup>
          </Grid>
        </GridCol>
      </GridRow>
    </Grid>
  ));
}

export default function ShopDetails() {
  return (
    <Form>
      <Grid item xs={12}>
        <ClaimNumberDetailsForm />
      </Grid>
      <GridRow justify="center">
        <GridCol base={11}>
          <Heading type="h4" align="center">
            Let&apos;s help you find your shop
          </Heading>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Notification
                alert="Start typing your shop name below to help us find your shop"
                highlightType="informative"
              />
            </Grid>
            <Grid item xs={12}>
              <AlphanumericInput
                id="pac"
                type="text"
                labelVisual="Shop search..."
                value=""
              />
            </Grid>
          </Grid>
        </GridCol>
      </GridRow>
      <GridRow justify="center">
        <GridCol base={11}>
          <ShopLookup />
        </GridCol>
      </GridRow>
      <Heading type="h4" align="center">
        Have we got your details correct?
      </Heading>
      <Grid item xs={12}>
        <AddressDetailsForm />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          <br />
        </Typography>
      </Grid>
    </Form>
  );
}
