import React from 'react';
import { Show } from '@lmig/lmds-react';
import { ReactComponent as SmallIcon } from './CPRA_24px.svg';
import { ReactComponent as LargeIcon } from './CPRA_32px.svg';

export default function CPRAIcon() {
  return (
    <>
      <Show
        thresholds={[
          'lg', 'md', 'xl'
        ]}
      >
        <LargeIcon />
      </Show>
      <Show
        thresholds={[
          'sm', 'base'
        ]}
      >
        <SmallIcon />
      </Show>
    </>
  );
}
