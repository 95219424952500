import React from 'react';
import { useObserver } from 'mobx-react';
import { GridCol, GridRow, Notification } from '@lmig/lmds-react';

export default function SplashPage() {
  return useObserver(() => (
    <GridRow gutters justify="center">
      <GridCol base={12}></GridCol>
      <GridCol base={12}></GridCol>
      <GridCol base={8}>
        <Notification
          alert="Site down for maintenance"
          highlightType="informative"
        >
          Our site is currently down for maintenance as we strive to improve
          your user experience.
          <br />
          <br />
          Please check back shortly and{' '}
          <em>
            <strong>refresh this page</strong>
          </em>{' '}
          as we should be up and running again soon.
        </Notification>
      </GridCol>
    </GridRow>
  ));
}
