import React, { useCallback } from 'react';
import { useContext } from '../helpers/react-hooks';
import cuid from 'cuid';
import { Grid, Typography } from '@material-ui/core';
import Dropzone from './dropzone/Dropzone';
import { RootStoreContext } from '../App';
import PropTypes from 'prop-types';
import { ACCEPTED_FILES } from '../constants/constants';


const DocumentUpload = ({ category, text }) => {
  const store = useContext(RootStoreContext).supplementUploadStore;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map(async (file) => {
      const reader = new FileReader();
      const bytesToMegaBytes = file.size / (1024 * 1024);
      const fileSize = `${bytesToMegaBytes.toFixed(2)}MB`;
      let fileName = file.path;
      if (bytesToMegaBytes > 10) {
        store.maxFileSizeNames.add(file.name);
      } else {
        if (fileName.length > 200) {
          fileName = `${fileName.substring(0, 200)}.${fileName
            .split('.')
            .pop()}`; // In case of multiple (.) in filename, take the extension by getting the last item in the array;
        }
        store.totalUploadSize += bytesToMegaBytes;
        reader.onload = function (e) {
          store.queueFiles([
            {
              id: cuid(),
              category,
              name: fileName,
              src: e.target.result,
              size: fileSize,
              type: file.type
            }
          ]);
        };
        reader.readAsDataURL(file);
        return file;
      }
    });
    if (store.maxFileSizeNames.size > 0) {
      store.error.maxFileSizeError = true;
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5">{text}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Dropzone onDrop={onDrop} acceptedFiles={ACCEPTED_FILES} />
      </Grid>
    </Grid>
  );
};

DocumentUpload.propTypes = {
  category: PropTypes.string,
  text: PropTypes.string
};

export default DocumentUpload;
