import getEnv from '../utils/getEnv';

const winston = require('winston');
const { detect } = require('detect-browser');

const logger = winston.createLogger({
  transports: [
    new winston.transports.Http({
      path: 'righttouch/log',
      host: getEnv('HOST'),
      port: getEnv('PORT'),
      ssl: getEnv('SSL')
    })
  ]
});

if (getEnv('ENV') !== 'production') {
  logger.add(new winston.transports.Console());
}

// Function to filter any confidential fields from logging.
function filterShopDetailsForLogging(store) {
  const shopDetails = { ...store.shopDetailsStore.data };
  delete shopDetails.taxId;
  return shopDetails;
}

function getFullState(store) {
  switch (store.activeStep + 1) {
  case 1:
    return getBrowserInfo();
  case 2:
    return {
      shopDetailsStore: filterShopDetailsForLogging(store)
    };
  case 3:
    return {
      shopDetailsStore: filterShopDetailsForLogging(store),
      supplementTypeStore: store.supplementTypeStore
    };
  default:
    return {
      shopDetailsStore: filterShopDetailsForLogging(store),
      supplementTypeStore: store.supplementTypeStore,
      supplementUploadStore: store.supplementUploadStore.listFileDetails(),
      totalUploadSize: store.supplementUploadStore.totalUploadSize
    };
  }
}

function getStepState(store) {
  switch (store.activeStep + 1) {
  case 1:
    return {
      shopDetailsStore: filterShopDetailsForLogging(store)
    };
  case 2:
    return {
      supplementTypeStore: store.supplementTypeStore
    };
  case 3:
    return {
      supplementUploadStore: store.supplementUploadStore.listFileDetails()
    };
  default:
    return {};
  }
}

/* eslint-disable camelcase */
export function logEnteredStep(store) {
  logger.info(
    `client - ${JSON.stringify({
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'entered_step',
      step: store.activeStep + 1,
      state: getFullState(store)
    })}`, 
    {
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'entered_step',
      step: store.activeStep + 1,
      state: getFullState(store)
    }
  );
}

export function logValidateFailed(store) {
  logger.info(
    `client - ${JSON.stringify({
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'validate_failed',
      step: store.activeStep + 1,
      state: getStepState(store)
    })}`,
    {
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'validate_failed',
      step: store.activeStep + 1,
      state: getStepState(store)
    }
  );
}

export function logSubmitStarted(store) {
  const totalUploadSize = `${store.supplementUploadStore.totalUploadSize.toFixed(
    3
  )}MB`;
  logger.info(
    `client - ${JSON.stringify({
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_started',
      totalUploadSize
    })}`,
    {
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_started',
      totalUploadSize
    }
  );
}

export function logSubmitOk(store) {
  logger.info(
    `client - ${JSON.stringify({
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_ok',
      state: getFullState(store)
    })}`,
    {
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_ok',
      state: getFullState(store)
    }
  );
}

export function logSubmitFailed(store, error) {
  const totalUploadSize = `${store.supplementUploadStore.totalUploadSize.toFixed(
    3
  )}MB`;
  logger.info(
    `client - ${JSON.stringify({
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_failed',
      totalUploadSize,
      error,
      state: getFullState(store)
    })}`,
    {
      supplementId: store.id,
      claimNumber: store.shopDetailsStore.data.claimNumber.value,
      side: 'client',
      event: 'submit_failed',
      totalUploadSize,
      error,
      state: getFullState(store)
    }
  );
}
/* eslint-enable camelcase */

export function getBrowserInfo() {
  const browser = detect();

  if (browser) {
    return {
      browser: {
        name: browser.name,
        version: browser.version,
        os: browser.os
      }
    };
  } else {
    return 'Browser details not found';
  }
}
