import { action, decorate, observable } from 'mobx';
import { PART_OPTIONS, ERROR_LIST } from '../constants/constants';

export default class SupplementTypeStore {
  data = {
    typeDamage: {
      value: false,
      text: 'Hidden / Additional Damage',
      isPart: false
    },
    typeParts: {
      value: false,
      text: 'Additional / Changed Parts',
      isPart: false
    },
    typeLabor: {
      value: false,
      text: 'Change in Labor Hours',
      isPart: false
    },
    typeSubletInvoice: {
      value: false,
      text: 'Sublet Invoice',
      isPart: false
    },
    typeOther: {
      value: false,
      text: 'Other (Freeform)',
      isPart: false
    },
    partNeededAdditional: {
      value: false,
      text: 'Additional Parts Needed',
      isPart: true
    },
    partChange: {
      value: false,
      text: 'Change in Part Type (i.e. LKQ to A/M)',
      isPart: true
    },
    partDamaged: {
      value: false,
      text: 'Defective / Damaged Part(s)',
      isPart: true
    },
    partUnavailable: {
      value: false,
      text: 'Part(s) No Longer Available',
      isPart: true
    },
    partPriceIncrease: {
      value: false,
      text: 'Part Price Increase',
      isPart: true
    },
    partOther: {
      value: false,
      text: 'Part Vendor Issue',
      isPart: true
    },
    otherReason: {
      value: ''
    }
  };

  supplementTypeOption = [];
  supplementPartInfo = [];

  noSelectionError = false;
  noPartsSelectionError = false;
  noOtherReasonError = false;

  init() {
    Object.keys(this.data).forEach((key) => {
      this.data[key].value = false;
    });
    this.data.otherReason.value = '';
    this.noSelectionError = false;
    this.noPartsSelectionError = false;
    this.noOtherReasonError = false;
    this.supplementTypeOption = [];
    this.supplementPartInfo = [];
  }

  set(name, value) {
    this.data[name].value = value;
    const isPart = this.data[name].isPart;
    const text = this.data[name].text;
    if (name === 'typeParts' && value === false) {
      this.clearParts();
      this.supplementPartInfo = [];
    }
    if (name === 'typeOther' && value === false) {
      this.clearOther();
    }
    if (name === 'otherReason' && value !== '') {
      this.noOtherReasonError = false;
    }
    if (value === true) {
      this.noSelectionError = false;
      isPart ? this.supplementPartInfo.push(text) : this.supplementTypeOption.push(text);
    }
    if (value === false) {
      if (isPart) {
        this.deleteString(this.supplementPartInfo, text);
      } else {
        this.deleteString(this.supplementTypeOption, text);
      }
    }
    if (this.data.typeParts.value === true) {
      this.checkParts();
    }
  }

  validate() {
    this.noSelectionError = !this.isAnyTypeSelected();
    this.noPartsSelectionError = !this.isValidPartChange();
    this.noOtherReasonError = !this.isValidOtherReason();
    return (
      this.noSelectionError ||
      this.noPartsSelectionError ||
      this.noOtherReasonError
    );
  }

  clearParts() {
    this.noPartsSelectionError = false;
    PART_OPTIONS.forEach((e) => {
      this.data[e.partOption].value = false;
    });
  }

  clearOther() {
    this.data.otherReason.value = '';
    this.noOtherReasonError = false;
  }

  checkParts() {
    PART_OPTIONS.forEach((e) => {
      if (this.data[e.partOption].value === true) {
        this.noPartsSelectionError = false;
      }
    });
  }

  deleteString(array, string) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === string) {
        array.splice(i, 1);
      }
    }
  }

  isAnyTypeSelected() {
    return (
      this.data.typeDamage.value ||
      this.data.typeParts.value ||
      this.data.typeLabor.value ||
      this.data.typeSubletInvoice.value ||
      this.data.typeOther.value
    );
  }

  isValidPartChange() {
    if (this.data.typeParts.value) {
      return (
        this.data.partNeededAdditional.value ||
        this.data.partChange.value ||
        this.data.partUnavailable.value ||
        this.data.partDamaged.value ||
        this.data.partPriceIncrease.value ||
        this.data.partOther.value
      );
    } else {
      return true;
    }
  }

  isValidOtherReason() {
    return !(
      this.data.typeOther.value && this.data.otherReason.value.length === 0
    );
  }

  setErrorMsg(errorType) {
    if (this[errorType]) {
      const errorMessages = [];
      errorMessages.push({
        text: ERROR_LIST.supplementType[errorType],
        type: 'error'
      });
      return errorMessages;
    } else {
      return undefined;
    }
  }
}

decorate(SupplementTypeStore, {
  data: observable,
  error: observable,
  noSelectionError: observable,
  noPartsSelectionError: observable,
  noOtherReasonError: observable,
  init: action,
  set: action,
  validate: action
});
