import React from 'react';
import { Grid } from '@material-ui/core';
import './SupFooter.scss';
import { Footer, Disclaimer, Link } from '@lmig/lmds-react';
import getEnv from '../utils/getEnv';
import CPRAIcon from './CPRAIcon';

export default function SupFooter() {
  const CPRA_PROD_URL =
    'https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292';
  const CPRA_NON_PROD_URL =
    'https://privacyportaluat.onetrust.com/hosted-webform/consent/7acbfc95-48e8-451b-90d0-b392f6597d85/3fcd7033-3566-4030-ac46-37f202757f2b'; // eslint-disable-line
  const env = getEnv('ENV');

  return (
    <Footer fullWidth className="footer-component liberty-footer">
      <Grid container className="footer-container" justifyContent="center">
        <Link
          href="https://www.libertymutualgroup.com/about-lm/our-company/privacy-policy"
          onBackground="blue"
        >
          Privacy Policy
        </Link>
        <span className="footer-pipe">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
        <Link
          href="https://www.libertymutualgroup.com/about-lm/our-company/security-policy"
          onBackground="blue"
        >
          Security Policy
        </Link>
        <span className="footer-pipe">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
        <Link
          href="https://www.libertymutualgroup.com/about-lm/corporate-information/web-accessibility-policy"
          onBackground="blue"
        >
          Web Accessibility Policy
        </Link>
        <span className="footer-pipe">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
        <Link
          href="https://www.libertymutualgroup.com/about-lm/our-company/terms-and-conditions"
          onBackground="blue"
        >
          Terms and Conditions
        </Link>
        <span className="footer-pipe">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
        <Link
          href="https://www.libertymutualgroup.com/about-lm/our-company/fraud-protection-tips"
          onBackground="blue"
        >
          Fraud Protection
        </Link>
      </Grid>
      <Grid container className="lower-container" justifyContent="center">
        <Grid container item xs={8}>
          <Grid item xs={6}>
            <Disclaimer>
              <CPRAIcon />
              <Link
                variant="navigational"
                href={env === 'production' ? CPRA_PROD_URL : CPRA_NON_PROD_URL}
                className="CPC-footer-link"
              >
                Your California Privacy Choices
              </Link>
              <Link
                variant="navigational"
                onClick={() => {
                  window.utag.gdpr.showDoNotSellPrompt();
                }}
                className="CPRA-footer-link"
              >
                Do Not Sell or Share My Personal Information (CA Residents Only)
              </Link>
              <p>
                &copy; {new Date().getFullYear()} Liberty Mutual Insurance
                Company, 175 Berkeley Street, Boston, MA 02116
              </p>
              <p className="lower-links">
                Liberty Mutual is an{' '}
                <Link
                  /* eslint-disable-next-line max-len */
                  href="https://www.libertymutualgroup.com/about-lm/corporate-information/equal-employment-opportunity-and-anti-discrimination-policy"
                  target="cybertrust"
                >
                  Equal Opportunity Employer
                </Link>{' '}
                and an Equal Housing Insurer.
              </p>
            </Disclaimer>
          </Grid>
          <Grid item xs={6}>
            <div className="footer-component">
              <ul className="footer-logos">
                <li>
                  <a
                    className="footer-icon cybertrust"
                    href="https://smp-01.verizonbusiness.com/certinfo/certified.do?CERTID=072505G7001"
                    target="cybertrust"
                  >
                    {' '}
                  </a>
                </li>
                <li>
                  <a
                    className="footer-icon bbb"
                    /* eslint-disable-next-line max-len */
                    href="https://www.bbb.org/boston/business-reviews/insurance-services/liberty-mutual-group-in-boston-ma-89/#bbbonlineclick"
                    target="bbb"
                  >
                    {' '}
                  </a>
                </li>
                <li>
                  <a
                    className="footer-icon verisign"
                    /* eslint-disable-next-line max-len */
                    href="https://sealinfo.websecurity.norton.com/splash?form_file=fdf/splash.fdf&type=GOLD&sealid=1&dn=www.libertymutual.com&lang=en"
                    target="verisign"
                  >
                    {' '}
                  </a>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Footer>
  );
}
