import React, { useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#919191',
  borderStyle: 'dashed',
  backgroundColor: '#F5F5F5',
  color: '#343741',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

const Dropzone = ({ onDrop, acceptedFiles }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: acceptedFiles });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragReject ? (
          <p>You are trying to upload an invalid file type</p>
        ) : (
          <p>
            Drag &apos;n&apos; drop your documents here, or click to select from
            a folder
          </p>
        )}
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  acceptedFiles: PropTypes.string
};

export default Dropzone;
