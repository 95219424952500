import { action, decorate, observable } from 'mobx';
import cuid from 'cuid';
import ShopDetailsStore from './ShopDetailsStore';
import SupplementTypeStore from './SupplementTypeStore';
import SupplementUploadStore from './SupplementUploadStore';
import { postSuppData } from '../utils/postSuppData';
import {
  logEnteredStep,
  logSubmitFailed,
  logSubmitOk,
  logSubmitStarted,
  logValidateFailed,
  getBrowserInfo
} from '../utils/logger';
import { DIRECTION_TO_PAY_OPTIONS } from '../constants/constants';

class RootStore {
  shopDetailsStore = new ShopDetailsStore();
  supplementTypeStore = new SupplementTypeStore();
  supplementUploadStore = new SupplementUploadStore();
  browser = getBrowserInfo();

  constructor() {
    this.shopDetailsStore = new ShopDetailsStore(this);
    this.supplementTypeStore = new SupplementTypeStore(this);
    this.supplementUploadStore = new SupplementUploadStore(this);
    this.browser = getBrowserInfo();
    this.firstStep();
  }

  titles = ['Shop Details', 'Supplement Type', 'Document Upload', 'Review'];

  CompletionDialogStates = {
    CLOSED: 1,
    SENDING: 2,
    SENT: 3,
    ERROR: 4
  };

  id = null;
  // set validate to false to disable validations for each step. useful for debugging
  validate = true;
  googleMapsLoading = false;
  googleMapsLoaded = false;
  activeStep = 0;
  completionDialogState = this.CompletionDialogStates.CLOSED;

  firstStep() {
    this.id = cuid();
    this.shopDetailsStore.init();
    this.supplementTypeStore.init();
    this.supplementUploadStore.init();
    this.activeStep = 0;
    this.completionDialogState = this.CompletionDialogStates.CLOSED;
    logEnteredStep(this);
  }

  prevStep() {
    if (this.activeStep > 0) {
      --this.activeStep;
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
      logEnteredStep(this);
    }
  }

  nextStep() {
    if (this.isValidStep()) {
      if (!this.isLastStep) {
        ++this.activeStep;
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
        logEnteredStep(this);
      } else {
        this.completionDialogState = this.CompletionDialogStates.SENDING;
        logSubmitStarted(this);
      }
    } else {
      logValidateFailed(this);
    }
  }

  get isLastStep() {
    return this.activeStep === this.titles.length - 1;
  }

  get isFirstStep() {
    return this.activeStep === 0;
  }

  isValidStep() {
    if (this.validate) {
      if (this.activeStep === 0) {
        return !this.shopDetailsStore.validate();
      } else if (this.activeStep === 1) {
        return !this.supplementTypeStore.validate();
      } else if (this.activeStep === 2) {
        const supplementError = this.supplementUploadStore.validate();
        const supportError = this.supplementUploadStore.validateSupportingDocs();
        let directionToPayError = false;
        if (
          this.shopDetailsStore.data.directionToPay.value ===
          DIRECTION_TO_PAY_OPTIONS[0].value
        ) {
          directionToPayError = this.supplementUploadStore.validateDirectionToPayDocs();
        }
        return !supplementError && !supportError && !directionToPayError;
      } else {
        return true;
      }
    }
    return true;
  }

  isCompletionDialogOpen() {
    return this.completionDialogState !== this.CompletionDialogStates.CLOSED;
  }

  doPostData() {
    this.supplementUploadStore.files.forEach((element, idx) => {
      element.rawFile.name = `${idx}_${element.rawFile.category}_${element.rawFile.name}`;
    });
    postSuppData(this)
      .then(() => {
        this.completionDialogState = this.CompletionDialogStates.SENT;
        logSubmitOk(this);
      })
      .catch((error) => {
        this.completionDialogState = this.CompletionDialogStates.ERROR;
        logSubmitFailed(this, error);
      });
  }
}

decorate(RootStore, {
  googleMapsLoading: observable,
  googleMapsLoaded: observable,
  activeStep: observable,
  completionDialogState: observable,
  firstStep: action,
  prevStep: action,
  nextStep: action,
  setAlert: action
});

const rootStoreInstance = new RootStore();
export default rootStoreInstance;
