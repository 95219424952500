/* eslint-disable max-len */
// 50MB limit for documents uploaded
export const MAX_TOTAL_FILE_SIZE = 50;
// limit number of files to 200
export const MAX_FILE_COUNT = 200;
export const ACCEPTED_FILES = 'image/*, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .rtf';
export const STATE_LIST = [
  // State Object
  { code: 'AL', state: 'Alabama' },
  { code: 'AK', state: 'Alaska' },
  { code: 'AZ', state: 'Arizona' },
  { code: 'AR', state: 'Arkansas' },
  { code: 'CA', state: 'California' },
  { code: 'CO', state: 'Colorado' },
  { code: 'CT', state: 'Connecticut' },
  { code: 'DE', state: 'Delaware' },
  { code: 'DC', state: 'District of Columbia' },
  { code: 'FL', state: 'Florida' },
  { code: 'GA', state: 'Georgia' },
  { code: 'HI', state: 'Hawaii' },
  { code: 'ID', state: 'Idaho' },
  { code: 'IL', state: 'Illinois' },
  { code: 'IN', state: 'Indiana' },
  { code: 'IA', state: 'Iowa' },
  { code: 'KS', state: 'Kansas' },
  { code: 'KY', state: 'Kentucky' },
  { code: 'LA', state: 'Louisiana' },
  { code: 'ME', state: 'Maine' },
  { code: 'MD', state: 'Maryland' },
  { code: 'MA', state: 'Massachusetts' },
  { code: 'MI', state: 'Michigan' },
  { code: 'MN', state: 'Minnesota' },
  { code: 'MS', state: 'Mississippi' },
  { code: 'MO', state: 'Missouri' },
  { code: 'MT', state: 'Montana' },
  { code: 'NE', state: 'Nebraska' },
  { code: 'NV', state: 'Nevada' },
  { code: 'NH', state: 'New Hampshire' },
  { code: 'NJ', state: 'New Jersey' },
  { code: 'NM', state: 'New Mexico' },
  { code: 'NY', state: 'New York' },
  { code: 'NC', state: 'North Carolina' },
  { code: 'ND', state: 'North Dakota' },
  { code: 'OH', state: 'Ohio' },
  { code: 'OK', state: 'Oklahoma' },
  { code: 'OR', state: 'Oregon' },
  { code: 'PA', state: 'Pennsylvania' },
  { code: 'RI', state: 'Rhode Island' },
  { code: 'SC', state: 'South Carolina' },
  { code: 'SD', state: 'South Dakota' },
  { code: 'TN', state: 'Tennessee' },
  { code: 'TX', state: 'Texas' },
  { code: 'UT', state: 'Utah' },
  { code: 'VT', state: 'Vermont' },
  { code: 'VA', state: 'Virginia' },
  { code: 'WA', state: 'Washington' },
  { code: 'WV', state: 'West Virginia' },
  { code: 'WI', state: 'Wisconsin' },
  { code: 'WY', state: 'Wyoming' }
];
export const ERROR_LIST = {
  shopDetails: {
    claimNumber:
      'Please provide a valid claim number in the format XXXXXXXXX-XXXX.',
    dollarAmount:
      'Please provide the value of the supplement amount being requested. This cannot be left blank.',
    estimatedCompletionDate:
      'Please provide an estimated completion date for this repair. Must be in the format MM/DD/YYYY.',
    directionToPay:
      'Please select either YES or NO if you want to share your Direction to Pay document with us.',
    shopEmail: 'Please provide a valid email address.',
    shopContact:
      'Please provide a name so we can contact you in the event of any queries.',
    taxId: 'Please provide a valid Federal Tax ID in the format of XX-XXXXXXX or leave the field empty.',
    shopName: 'Please provide a shop name.',
    shopAddress1: 'Please provide address.',
    shopAddress2: 'Please provide city.',
    shopState: 'Please select a valid state.',
    shopZipCode: 'Please provide a valid zip code.',
    shopTelephone: 'Please provide a valid telephone number.'
  },
  supplementType: {
    noSelectionError:
      'Please select at least one option which accurately reflects the nature of the supplement request.',
    noPartsSelectionError:
      'Please select at least one option under Additional / Changed Parts.',
    noOtherReasonError:
      'You have selected Other, please provide a reason for this in the text box below.'
  },
  supplementUpload: {
    noSupplementError: 'Please upload ONE document',
    maxTotalFileSizeError: `The total size of files uploaded (fileSizeMB) exceeds the maximum size of ${MAX_TOTAL_FILE_SIZE}MB. Please remove one or more files to stay within this limit.`,
    maxFileCountError: `The total number of files uploaded (fileCount) exceeds the maximum number of ${MAX_FILE_COUNT}. Please remove fileCountToRemove file(s) to stay within this limit.`,
    noSupportError: 'Please upload at least one document',
    tooManyDirectionToPayError: 'Please upload ONE document'
  }
};
export const PART_OPTIONS = [
  {
    partOption: 'partNeededAdditional',
    optionText: 'Additional Parts Needed'
  },
  {
    partOption: 'partChange',
    optionText: 'Change in Part Type (i.e. LKQ to A/M)'
  },
  {
    partOption: 'partDamaged',
    optionText: 'Defective / Damaged Part(s)'
  },
  {
    partOption: 'partUnavailable',
    optionText: 'Part(s) No Longer Available'
  },
  {
    partOption: 'partPriceIncrease',
    optionText: 'Part Price Increase'
  },
  {
    partOption: 'partOther',
    optionText: 'Part Vendor Issue'
  }
];
export const DIRECTION_TO_PAY_OPTIONS = [
  {
    value: 'YES - You\'ll be asked to upload on a following screen'
  },
  {
    value: 'NO - You can always submit one later'
  },
  {
    value: 'It was already submitted'
  }
];
