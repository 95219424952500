import React from 'react';
import './App.scss';
import { Content, Header, LogoCobrand } from '@lmig/lmds-react';
import Stepper from './components/Stepper';
import SplashPage from './components/SplashPage';
import SupFooter from './components/SupFooter';
import { makeStyles } from '@material-ui/core/styles';
import analyticsManager from '@lmig/crab-rangoons';
import getEnv from './utils/getEnv';
import rootStoreInstance from './store/RootStore';
export const RootStoreContext = React.createContext(rootStoreInstance);

const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  divider: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto'
  }
}));

function App() {
  const classes = useStyles();

  analyticsManager.init(null, {
    isProd: getEnv('ENV') === 'production',
    galileoUrl: getEnv('galileoUrl'),
    tealiumUrl: getEnv('tealiumUrl'),
    pageName: getEnv('pageName'),
    debugLogging: getEnv('ENV') !== 'production'
  });

  analyticsManager.dispatchPageload({
    pageName: getEnv('pageName'),
    pageSubName: 'Landing'
  });

  function setAppMode() {
    if (maintenanceMode.toLowerCase() === 'true') {
      return <SplashPage />;
    } else {
      return <Stepper />;
    }
  }

  return (
    <div className={classes.root}>
      <Header>
        <LogoCobrand />
      </Header>
      <Content>{setAppMode()}</Content>
      <div className={classes.divider} />
      <SupFooter />
    </div>
  );
}

export default App;
