import React, { useContext, useState } from 'react';
import DocumentUpload from './DocumentUpload';
import localizedStrings from '../constants/localizedStrings';
import { DIRECTION_TO_PAY_OPTIONS } from '../constants/constants';
import { Grid, Typography } from '@material-ui/core';
import {
  Notification,
  Card,
  Heading,
  List,
  ListItem,
  FieldGroup,
  IconAccordionCaretDown,
  IconAccordionCaretUp,
  Modal,
  Button,
  GridCol,
  GridRow
} from '@lmig/lmds-react';
import SupplementGrid from './SupplementGrid';
import { useObserver } from 'mobx-react';
import { RootStoreContext } from '../App';

const {
  DIRECTION_TO_PAY,
  SUPPLEMENT_UPLOAD,
  SUPPORTING_DOCS,
  ADDITIONAL_DAMAGE,
  PARTS,
  LABOR,
  OTHER
} = localizedStrings;

function setCardStyle(isShown, store, uploadType) {
  if (store.error[uploadType]) {
    return 'notifcation-Card-error';
  } else if (isShown) {
    return 'notifcation-Card-expanded';
  } else {
    return 'notifcation-Card';
  }
}

function highlightOnError(store, uploadType) {
  return store.error[uploadType] ? 'negative' : 'informative';
}

function alertOnError(error) {
  return error ? 'Important' : undefined;
}

export default function SupplementUpload() {
  const rootStore = useContext(RootStoreContext);
  const store = useContext(RootStoreContext).supplementUploadStore;
  const shopDetailsStore = useContext(RootStoreContext).shopDetailsStore;
  const [isSupplementShown, setIsSupplementShown] = useState(false);
  const [isSupportShown, setIsSupportShown] = useState(false);
  const [isDirectionShown, setIsDirectionShown] = useState(false);
  const toggleSupplementInfo = () => setIsSupplementShown(!isSupplementShown);
  const toggleSupportInfo = () => setIsSupportShown(!isSupportShown);
  const toggleDirectionInfo = () => setIsDirectionShown(!isDirectionShown);
  const totalFileSize = store.totalUploadSize.toFixed(2);
  const totalFileCount = store.files.length;


  return useObserver(() => (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12}>
        {/* Supplement Document Upload Component */}
        <FieldGroup
          isFull
          disableMargins="all"
          messages={store.setErrorMsg(
            ['noSupplementError', 'maxTotalFileSizeError', 'maxFileCountError'],
            totalFileSize,
            totalFileCount
          )}
          messagesRole="status"
        >
          <DocumentUpload category="supplement" />
        </FieldGroup>
      </Grid>
      <Grid item xs={12}>
        {/* Supplement Document Additional Information Card */}
        <Card
          id="supplementInfoCard"
          className={setCardStyle(isSupplementShown, store, 'supplementError')}
          onClick={toggleSupplementInfo}
        >
          <Grid container spacing={2}>
            <Grid item xs={11}>
              {SUPPLEMENT_UPLOAD.MORE_REQUEST_INFO}
            </Grid>
            <Grid item xs={1}>
              {isSupplementShown ? (
                <IconAccordionCaretDown />
              ) : (
                <IconAccordionCaretUp />
              )}
            </Grid>
          </Grid>
          {isSupplementShown || store.error.supplementError ? (
            <Notification
              className="uploadInfo"
              alert={alertOnError(store.error.supplementError)}
              highlightType={highlightOnError(store, 'supplementError')}
            >
              {SUPPLEMENT_UPLOAD.PART1}
              <List>
                <ListItem>{SUPPLEMENT_UPLOAD.PART2}</ListItem>
                <ListItem>{SUPPLEMENT_UPLOAD.PART3}</ListItem>
                <ListItem>{SUPPLEMENT_UPLOAD.PART4}</ListItem>
              </List>
            </Notification>
          ) : undefined}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <SupplementGrid category="supplement" />
      </Grid>
      <Heading type="h4" align="center">
        Please upload your Supporting document(s)
      </Heading>
      <Grid item xs={12}>
        {/* Supporting Documents Upload Component */}
        <FieldGroup
          isFull
          disableMargins="all"
          messages={store.setErrorMsg(
            ['noSupportError', 'maxTotalFileSizeError', 'maxFileCountError'],
            totalFileSize,
            totalFileCount
          )}
          messagesRole="status"
        >
          <DocumentUpload category="support" />
        </FieldGroup>
      </Grid>
      <Grid item xs={12}>
        {/* Supporting Documents Additional Information Card */}
        <Card
          id="supportingInfoCard"
          className={setCardStyle(isSupportShown, store, 'supportError')}
          onClick={toggleSupportInfo}
        >
          <Grid container spacing={2}>
            <Grid item xs={11}>
              {SUPPORTING_DOCS.MORE_SUPPORT_INFO}
            </Grid>
            <Grid item xs={1}>
              {isSupportShown ? (
                <IconAccordionCaretDown />
              ) : (
                <IconAccordionCaretUp />
              )}
            </Grid>
          </Grid>
          {isSupportShown || store.error.supportError ? (
            <Notification
              className="uploadInfo"
              alert={alertOnError(store.error.supportError)}
              highlightType={highlightOnError(
                rootStore.supplementUploadStore,
                'supportError'
              )}
            >
              {SUPPORTING_DOCS.PART1}
              <List>
                {rootStore.supplementTypeStore.data.typeDamage.value && (
                  <ListItem>{ADDITIONAL_DAMAGE.PART1}</ListItem>
                )}
                {rootStore.supplementTypeStore.data.typeParts.value && (
                  <ListItem>{PARTS.PART1}</ListItem>
                )}
                {rootStore.supplementTypeStore.data.typeLabor.value && (
                  <ListItem>{LABOR.PART1}</ListItem>
                )}
                {rootStore.supplementTypeStore.data.typeOther.value && (
                  <ListItem>{OTHER.PART1}</ListItem>
                )}
                <ListItem>{SUPPORTING_DOCS.PART2}</ListItem>
                <ListItem>{SUPPORTING_DOCS.PART3}</ListItem>
              </List>
            </Notification>
          ) : undefined}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <SupplementGrid category="support" />
      </Grid>
      {shopDetailsStore.data.directionToPay.value === DIRECTION_TO_PAY_OPTIONS[0].value && (<>
        <Heading type="h4" align="center">
          Please upload your Direction To Pay document
        </Heading>
        <Grid item xs={12}>
          {/* Direction To Pay Upload Component */}
          <FieldGroup
            isFull
            disableMargins="all"
            messages={store.setErrorMsg(
              ['maxTotalFileSizeError', 'maxFileCountError', 'tooManyDirectionToPayError'],
              totalFileSize,
              totalFileCount
            )}
            messagesRole="status"
          >
            <DocumentUpload category="directionToPay" />
          </FieldGroup>
        </Grid>
        <Grid item xs={12}>
          {/* Direction To Pay Additional Information Card */}
          <Card
            id="directionToPayInfoCard"
            className={setCardStyle(isDirectionShown, store, 'directionToPayError')}
            onClick={toggleDirectionInfo}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                {DIRECTION_TO_PAY.MORE_DIRECTION_INFO}
              </Grid>
              <Grid item xs={1}>
                {isDirectionShown ? (
                  <IconAccordionCaretDown />
                ) : (
                  <IconAccordionCaretUp />
                )}
              </Grid>
            </Grid>
            {isDirectionShown || store.error.directionToPayError ? (
              <Notification
                className="uploadInfo"
                alert={alertOnError(store.error.directionToPayError)}
                highlightType={highlightOnError(
                  rootStore.supplementUploadStore,
                  'directionToPayError'
                )}
              >
                {DIRECTION_TO_PAY.PART1}
                <List>
                  <ListItem>{DIRECTION_TO_PAY.PART2}</ListItem>
                  <ListItem>{DIRECTION_TO_PAY.PART3}</ListItem>
                  <ListItem>{DIRECTION_TO_PAY.PART4}</ListItem>
                </List>
              </Notification>
            ) : undefined}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <SupplementGrid category="directionToPay" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            <br />
          </Typography>
        </Grid>
      </>)}
      <Modal
        isOpen={store.error.maxFileSizeError}
        size="large"
        modalStyle="error"
        title="Following files exceed 10MB limit:"
      >
        <List>
          {Array.from(store.maxFileSizeNames).map((value, index) => (
            <ListItem key={index}>{value}</ListItem>
          ))}
        </List>
        The above files have not been uploaded.
        <br />
        <br />
        <GridCol>
          <GridRow base={3} justify="end">
            <Button
              size="small"
              onClick={() => store.clearMaxFileSizeError()}
              variant="secondary"
              autoFocus
            >
              OK
            </Button>
          </GridRow>
        </GridCol>
      </Modal>
    </Grid>
  ));
}
