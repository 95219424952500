import { action, decorate, observable } from 'mobx';
import {
  MAX_TOTAL_FILE_SIZE,
  MAX_FILE_COUNT,
  ERROR_LIST
} from '../constants/constants';

const queuedFile = {};

export default class SupplementUploadStore {
  files = [];
  totalUploadSize = 0;
  maxTotalFileSize = MAX_TOTAL_FILE_SIZE;
  maxFileCount = MAX_FILE_COUNT;

  error = {
    supplementError: false,
    supportError: false,
    directionToPayError: false,
    tooManyDirectionToPayError: false,
    noSupplementError: false,
    noSupportError: false,
    maxFileSizeError: false,
    maxFileNameLengthError: false,
    maxTotalFileSizeError: false,
    maxFileCountError: false,
    invalidSupplementError: false,
    invalidSupportError: false,
    invalidDirectionToPayError: false
  };

  maxFileSizeNames = new Set();
  maxFileSizeSizes = [];

  init() {
    this.files = [];
    this.maxTotalFileSize = MAX_TOTAL_FILE_SIZE;
    this.maxFileCount = MAX_FILE_COUNT;
    this.totalUploadSize = 0;
    this.error = {
      supplementError: false,
      supportError: false,
      directionToPayError: false,
      tooManyDirectionToPayError: false,
      noSupplementError: false,
      noSupportError: false,
      maxFileSizeError: false,
      maxFileNameLengthError: false,
      maxTotalFileSizeError: false,
      maxFileCountError: false,
      invalidSupplementError: false,
      invalidSupportError: false,
      invalidDirectionToPayError: false
    };
    this.maxFileSizeNames = new Set();
  }

  filterFiles(category) {
    const filesByCategory = this.files.filter(
      (file) => file.rawFile.category === category
    );
    const filesWithNoSrc = this.files.filter(
      (file) =>
        (!file.rawFile.src || !file.rawFile.src.includes(';base64')) &&
        file.rawFile.category === category
    );
    return { filesByCategory, filesWithNoSrc };
  }

  queueFiles = (files) => {
    const newFiles = files.map((rawFile) => ({ ...queuedFile, rawFile }));
    this.files.push(...newFiles);
    for (const newFile of newFiles) {
      const category = newFile.rawFile.category;
      switch (category) {
      case 'supplement':
        this.validate();
        break;
      case 'support':
        this.validateSupportingDocs();
        break;
      case 'directionToPay':
        this.validateDirectionToPayDocs();
        break;
      default:
        break;
      }
    }
  };

  removeFile = (id) => {
    const index = this.files.findIndex((e) => e.rawFile.id === id);
    if (index > -1) {
      const fileToRemove = this.files.filter((file) => file.rawFile.id === id);
      const bytesToMegaBytes = fileToRemove[0].rawFile.size.slice(0, -2);
      this.totalUploadSize -= bytesToMegaBytes;
      this.files.splice(index, 1);
      this.validate();
      this.validateSupportingDocs();
      this.validateDirectionToPayDocs();
    }
  };

  validateAllFiles() {
    this.error.maxTotalFileSizeError =
      this.totalUploadSize > this.maxTotalFileSize;
    this.error.maxFileCountError = this.files.length > this.maxFileCount;
  }

  validate() {
    const { filesByCategory, filesWithNoSrc } = this.filterFiles('supplement');
    this.error.noSupplementError = filesByCategory.length !== 1;
    this.error.invalidSupplementError = filesWithNoSrc.length > 0;
    this.validateAllFiles();
    this.error.supplementError =
      this.error.noSupplementError ||
      this.error.maxTotalFileSizeError ||
      this.error.invalidSupplementError ||
      this.error.maxFileCountError;
    return this.error.supplementError;
  }

  validateSupportingDocs() {
    const { filesByCategory, filesWithNoSrc } = this.filterFiles('support');
    this.error.noSupportError = filesByCategory.length <= 0;
    this.error.invalidSupportError = filesWithNoSrc.length > 0;
    this.validateAllFiles();
    this.error.supportError =
      this.error.noSupportError ||
      this.error.maxTotalFileSizeError ||
      this.error.invalidSupportError ||
      this.error.maxFileCountError;
    return this.error.supportError;
  }

  validateDirectionToPayDocs() {
    const { filesByCategory, filesWithNoSrc } = this.filterFiles('directionToPay');
    this.error.tooManyDirectionToPayError = filesByCategory.length > 1;
    this.error.invalidDirectionToPayError = filesWithNoSrc.length > 0;
    this.validateAllFiles();
    this.error.directionToPayError =
      this.error.invalidDirectionToPayError ||
      this.error.maxTotalFileSizeError ||
      this.error.maxFileCountError ||
      this.error.tooManyDirectionToPayError;
    return this.error.directionToPayError;
  }

  listFileDetails() {
    return this.files.map((e) => ({
      fileName: e.rawFile.name,
      fileSize: e.rawFile.size
    }));
  }

  listSupplementRequestDocuments() {
    const supplementRequestDocs = this.files.filter(
      (file) => file.rawFile.category === 'supplement'
    );

    return supplementRequestDocs.map((e) => e.rawFile.name).join(', ');
  }

  setErrorMsg(errorTypes, totalFileSize, fileCount) {
    const errorMessages = [];
    for (const errorType of errorTypes) {
      if (this.error[errorType]) {
        const errorMsg = ERROR_LIST.supplementUpload[errorType]
          .replace('fileSize', totalFileSize)
          .replace('fileCount', fileCount)
          .replace('fileCountToRemove', fileCount - MAX_FILE_COUNT);
        errorMessages.push({
          text: errorMsg,
          type: 'error'
        });
      }
    }
    if (errorMessages.length > 0) {
      return errorMessages;
    } else {
      return undefined;
    }
  }

  clearMaxFileSizeError() {
    this.error.maxFileSizeError = false;
    this.maxFileSizeNames.clear();
    this.maxFileSizeSizes = [];
  }
}

decorate(SupplementUploadStore, {
  files: observable,
  error: observable,
  totalUploadSize: observable,
  maxFileSizeNames: observable,
  init: action,
  validate: action,
  validateSupportingDocs: action,
  validateDirectionToPayDocs: action
});
