import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Link
} from '@material-ui/core';
import { Button } from '@lmig/lmds-react-button';
import { RootStoreContext } from '../App';
import ShopDetails from './ShopDetails';
import SupplementType from './SupplementType';
import SupplementUpload from './SupplementUpload';
import Review from './Review';
import localizedStrings from '../constants/localizedStrings';
import { makeStyles } from '@material-ui/styles';
import { Heading, BodyText } from '@lmig/lmds-react';
import getEnv from '../utils/getEnv';

const { HEADINGS, DIALOG_MSG } = localizedStrings;

const stepperStyles = makeStyles({
  stepIconRoot: {
    color: '#9D9E9E'
  },
  stepIconActive: {
    color: '#28A3AF !important'
  },
  stepIconCompleted: {
    color: '#28A3AF !important'
  }
});

export default function SupplementsStepper() {
  const root = useContext(RootStoreContext);
  const classes = stepperStyles();

  const content = {
    0: <ShopDetails />,
    1: <SupplementType />,
    2: <SupplementUpload />,
    3: <Review />
  };

  const onEntered = () => {
    if (root.isCompletionDialogOpen()) {
      root.doPostData();
    }
  };

  const handleCloseOnSent = () => {
    root.firstStep();
  };

  const handleCloseOnError = () => {
    root.firstStep();
    // Temporary fix for the window to reload upon error encountered
    window.location.reload(true);
  };
  
  function getDialogContent() {
    switch (root.completionDialogState) {
    case root.CompletionDialogStates.SENDING:
      return (
          <>
              <Heading type="h3-light">
                {DIALOG_MSG.UPLOADING}
              </Heading>
              <div style={{ display:'flex', justifyContent: 'center' }}>
                <CircularProgress />  
              </div>            
          </>
      );
    case root.CompletionDialogStates.SENT:
      return <>
              <Heading type="h3-light">
                {DIALOG_MSG.SUCCESS}
              </Heading>              
              <BodyText type="article">
               A Claim Representative will contact you within <b>2 business days.</b> 
               To continue tracking the status of your claim, you can view it below or create a new Claim Information Portal (CIP) account.
              </BodyText>          
              <h3 style={{ fontWeight:'normal' }}>Easily track your claims</h3>
              <BodyText type="article">
              Create a CIP account in just <b>4 minutes</b> to stay informed with the status of your claims anytime, anywhere. 
              Please note: Any documents uploaded today will take up to 24 hours to appear in the Claim Information Portal.
              </BodyText>
              <BodyText type="article">
                <p>
                  <Link 
                    target="_blank" 
                    href={`${getEnv('cipURL')}/registration?from=apd`}> {/* Added query for heap tracking */}
                    Create a new Claim Information Portal account
                  </Link>
                </p>
              </BodyText>
            </>;
    case root.CompletionDialogStates.ERROR:
      return <>
              <Heading type="h3-light">
                {DIALOG_MSG.INCOMPLETE}
              </Heading>              
              <BodyText type="article">
               After we review the supplement, you will hear from our appraisal team 
               or a Claim Representative within <b>2 business days.</b> 
               To track your claim and get access to our support team, you can View claim status below.
              </BodyText>                       
            </>;    
    default:
      return <>
            <Heading type="h3-light">
              {DIALOG_MSG.ERROR}
            </Heading>              
            <BodyText type="article">
            Sorry! An unexpected error has occurred, please contact the appraiser listed on the estimate.
            </BodyText>                       
            </>;
    }
  }

  function getDialogActions() {
    if (root.completionDialogState === root.CompletionDialogStates.SENT) {
      return (
        <p>
          <Button onClick={handleCloseOnSent} color="primary" variant="secondary" autoFocus>
          Upload another supplement
          </Button>
          <Button onClick={() => window.open(getEnv('cipURL'))} variant="primary" autoFocus>
            View Claim Status
          </Button>
        </p>
      );
    } else if (
      root.completionDialogState === root.CompletionDialogStates.ERROR
    ) {
      return (
        <p>
          <Button onClick={handleCloseOnError} color="primary" variant="secondary" autoFocus>
          Start Over
          </Button>

          <Button onClick={() => window.open(getEnv('cipURL'))} variant="primary" autoFocus>
          View Claim Status
          </Button>
        </p>
      );
    } else {
      return <></>;
    }
  }

  return useObserver(() => (
    <>
      <Grid container justifyContent="center">
        <Stepper id="stepper" activeStep={root.activeStep} alternativeLabel>
          {root.titles.map((title) => (
            <Step key={title}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.stepIconRoot,
                    active: classes.stepIconActive,
                    completed: classes.stepIconCompleted
                  }
                }}
              >
                {isMobile ? null : title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={11} sm={8}>
          <Heading id="stepHeading" type="h4" align="center">
            {HEADINGS[root.activeStep]}
          </Heading>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={11} sm={8}>
          {content[root.activeStep]}
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid container item xs={10} sm={3} alignItems="center">
          <Button
            dynamicWidth
            isLargeOnMobile
            type="submit"
            variant="primary"
            onClick={() => root.nextStep()}
          >
            {root.isLastStep ? 'Submit' : 'Next'}
          </Button>
        </Grid>
        <Grid container item xs={9} sm={2} alignItems="center">
          {!root.isFirstStep && (
            <Button
              id="back-btn"
              dynamicWidth
              isLargeOnMobile
              onClick={() => root.prevStep()}
            >
              Back
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={root.isCompletionDialogOpen()}
        TransitionProps={{
          onEntered
        }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseOnSent();
          }
        }}
      >
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions>{getDialogActions()}</DialogActions>
      </Dialog>
    </>
  ));
}

