const getEnvFromHostname = () => {
  const { hostname } = global.window.location;

  const env = {
    API: 'https://supplements.libertymutual.com',
    HOST: 'supplements.libertymutual.com',
    PORT: 443,
    SSL: true,
    ENV: 'production',
    galileoUrl: 'https://galileo.libertymutual.com/v1/apievent',
    tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/prod/utag.js',
    pageName: 'ShopSupplementIntakePortal',
    cipURL: 'https://cipautobody.libertymutual.com'
  };

  // prod
  if (hostname.includes('shop-supplement-intake-app.us-east-1')) {
    env.API = 'https://shop-supplement-intake-app.us-east-1.paas.lmig.com';
    env.HOST = 'shop-supplement-intake-app.us-east-1.paas.lmig.com';
    env.galileoUrl = 'https://dev.galileo.libertymutual.com/v1/apievent';
    env.tealiumUrl = 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js';
    env.cipURL = 'https://cipautobody.libertymutual.com';
  }

  // staging
  if (hostname.includes('staging')) {
    env.API = 'https://shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com';
    env.HOST = 'shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com';
    env.ENV = 'staging';
    env.galileoUrl = 'https://dev.galileo.libertymutual.com/v1/apievent';
    env.tealiumUrl = 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js';
    env.cipURL = 'https://ete-cipautobody.libertymutual.com';
  }

  if (hostname.includes('development')) {
    env.API =
      'https://shop-supplement-intake-app-development.us-east-1.np.paas.lmig.com';
    env.HOST = 'shop-supplement-intake-app-development.us-east-1.np.paas.lmig.com';
    env.ENV = 'development';
    env.galileoUrl = 'https://dev.galileo.libertymutual.com/v1/apievent';
    env.tealiumUrl = 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js';
    env.cipURL = 'https://ete-cipautobody.libertymutual.com';
  }

  if (hostname.startsWith('localhost')) {
    env.API = 'http://localhost:8080';
    env.HOST = 'localhost';
    env.ENV = 'development';
    env.PORT = 8080;
    env.SSL = false;
    env.galileoUrl = 'https://dev.galileo.libertymutual.com/v1/apievent';
    env.tealiumUrl = 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js';
    env.cipURL = 'https://ete-cipautobody.libertymutual.com';
  }

  if (hostname.startsWith('127.0.0.1')) {
    env.API = 'http://127.0.0.1:8080';
    env.HOST = '127.0.0.1';
    env.ENV = 'development';
    env.PORT = 8080;
    env.SSL = false;
    env.galileoUrl = 'https://dev.galileo.libertymutual.com/v1/apievent';
    env.tealiumUrl = 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js';
    env.cipURL = 'https://ete-cipautobody.libertymutual.com';
  }

  return env;
};

export default (property) => getEnvFromHostname()[property];
