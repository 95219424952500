/* eslint-disable max-len */
import LocalizedStrings from 'react-localization';

const localizedStrings = new LocalizedStrings({
  en: {
    HEADINGS: [
      'Tell us about your supplement',
      'Please select one or more from the following list to indicate the nature of your Supplement Request',
      'Please upload your Supplement Appraisal document (ONE only)',
      'Please review details'
    ],
    SUPPLEMENT_TYPE: {
      PARTS_CARD: 'Please select the reason(s) below that apply to the change. On the next screen, please upload the supporting documentation (i.e. invoices, no quotes, photos of damaged parts, etc.)',
      SUBLET_INVOICE_CARD_BEG: 'You have selected ',
      SUBLET_INVOICE_CARD_BOLD: 'Sublet Invoice',
      SUBLET_INVOICE_CARD_END: '; Please upload the supporting invoice(s) on the next screen',
      OTHER_CARD: 'You have selected Other to describe the supplement type you are submitting — Please provide the reason for your choice'

    },
    SUPPLEMENT_UPLOAD: {
      PART1: 'Please upload your Supplement Appraisal document (Supplement of Record)',
      PART2: 'Your document should be in PDF format',
      PART3: 'Your document should be less than 10MB in size',
      PART4: 'Filename should not exceed 200 characters',
      MORE_REQUEST_INFO:
        'Click for more info on uploading your Supplement Appraisal document...'
    },
    SUPPORTING_DOCS: {
      PART1: 'Please upload your Supporting documents',
      PART2: 'Each document should be less than 10MB in size',
      PART3: 'Filenames should not exceed 200 characters',
      MORE_SUPPORT_INFO:
        'Click for more info on uploading your Supporting document(s)...'
    },
    DIRECTION_TO_PAY: {
      PART1: 'Please upload your Direction To Pay document',
      PART2: 'Your document should be in PDF format',
      PART3: 'Your document should be less than 10MB in size',
      PART4: 'Filename should not exceed 200 characters',
      MORE_DIRECTION_INFO:
        'Click for more info on uploading your Direction to Pay document...'
    },
    ADDITIONAL_DAMAGE: {
      PART1:
      'Your documents should be pictures or in PDF format'
    },
    PARTS: {
      PART1: 'Parts Invoice / Estimate'
    },
    LABOR: {
      PART1: 'Labor Invoice / Estimate'
    },
    OTHER: {
      PART1: 'Additional Documentation'
    },
    DIALOG_MSG: {
      UPLOADING: 'Your supplement is uploading',
      SUCCESS: 'We received your supplement',
      INCOMPLETE: 'Something went wrong!',
      ERROR: 'Something went wrong!'
    }
  }
});

export default localizedStrings;
