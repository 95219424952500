import axios from 'axios';
import root from '../store/RootStore';
import getEnv from './getEnv';

window.initMap = function () {
  root.googleMapsLoaded = true;
};

function getGoogleMapsKey(callback) {
  axios({
    method: 'GET',
    url: `${getEnv('API')}/maps/key`
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      /* eslint-disable no-console */
      console.log(error);
      root.googleMapsLoading = false;
    });
}

export function loadGoogleMapsScript() {
  root.googleMapsLoading = true;
  getGoogleMapsKey((key) => {
    const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`;
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    document.head.appendChild(script);
  });
}

export function applyGoogleMapsControls() {
  // create google map elements
  const input = document.getElementById('pac-input');
  const searchBox = new window.google.maps.places.SearchBox(input);
  const map = new window.google.maps.Map(document.getElementById('shop-map'), {
    center: { lat: 51.5074, lng: 0.1278 },
    zoom: 12,
    mapTypeId: 'roadmap',
    fullscreenControl: false,
    mapTypeControl: false
  });

  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds());
  });

  // snap to current location
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      map.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
  }

  // handle results from map searches
  searchBox.addListener('places_changed', () => {
    const places = searchBox.getPlaces();
    if (places.length === 1) {
      const place = places[0];
      let address = '';
      let city = '';
      let state = '';
      let zipCode = '';

      // 'Address' and 'City' from the long address string
      // place.formatted_address --> format example: "4536 San Francisco Avenue, San Francisco, CA 12345, USA"
      const addressComponents = place.formatted_address.split(',');
      address = addressComponents[0].trim();
      city = addressComponents[1].trim();
      root.shopDetailsStore.set('shopAddress2', city);
      root.shopDetailsStore.set('shopAddress1', address);

      // 'Shop name'
      if (place.name && place.name !== address) {
        root.shopDetailsStore.set('shopName', place.name);
      } else {
        root.shopDetailsStore.set('shopName', '');
      }

      // Extract zip code and state from address_components
      for (const element of place.address_components) {
        const type = element.types[0];
        if (type === 'postal_code') {
          zipCode = element.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = element.short_name;
        }
      }

      // 'State'
      root.shopDetailsStore.set('shopState', state);

      // 'Zip code'
      root.shopDetailsStore.set('shopZipCode', zipCode);

      // 'Telephone'
      if (place.formatted_phone_number) {
        root.shopDetailsStore.set(
          'shopTelephone',
          place.formatted_phone_number
        );
      } else {
        root.shopDetailsStore.set('shopTelephone', '');
      }
    }

    // center map on search results
    const bounds = new window.google.maps.LatLngBounds();
    places.forEach((place) => {
      if (place.geometry) {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      }
    });
    map.fitBounds(bounds);
  });
}
