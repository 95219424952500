import React from 'react';
import { useContext } from '../helpers/react-hooks';
import PropTypes from 'prop-types';
import { RootStoreContext } from '../App';
import { useObserver } from 'mobx-react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { red } from '@material-ui/core/colors';

const pdfImage = require('../helpers/FileDoc.png');

function ImageContent(image) {
  const type =
    image.rawFile.src && image.rawFile.src.includes(';base64')
      ? image.rawFile.src.split('/')[0].split(':')[1]
      : 'Invalid file';
  const src = type !== 'image' ? pdfImage : image.rawFile.src;
  return <img alt="X" src={src} />;
}

function FilterImageByCategory(store, file, category) {
  const subtitle =
    !file.rawFile.src || !file.rawFile.src.includes(';base64') ? (
      <b style={{ color: red[500] }}> Invalid File </b>
    ) : (
      file.rawFile.size
    );
  if (file.rawFile.category === category.category) {
    return (
      <ImageListItem key={file.rawFile.id}>
        {ImageContent(file)}
        <ImageListItemBar
          title={file.rawFile.name}
          subtitle={subtitle}
          actionIcon={
            <IconButton onClick={() => store.removeFile(file.rawFile.id)}>
              <ClearIcon style={{ color: red[500] }} />
            </IconButton>
          }
        />
      </ImageListItem>
    );
  }
}

const SupplementGrid = (category) => {
  const store = useContext(RootStoreContext).supplementUploadStore;

  return useObserver(() => (
    <ImageList rowHeight={160} cols={5}>
      {store.files.map((file) => FilterImageByCategory(store, file, category))}
    </ImageList>
  ));
};

SupplementGrid.propTypes = {
  category: PropTypes.string
};

export default SupplementGrid;
