/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import { action, decorate, observable } from 'mobx';
import {
  addYears,
  isAfter,
  isBefore,
  isValid,
  parse,
  subYears
} from 'date-fns';
import { DIRECTION_TO_PAY_OPTIONS, ERROR_LIST } from '../constants/constants';

function isMandatory(val) {
  return val !== null && val !== undefined && val.length > 0;
}

function isValidClaimNumber(val) {
  const re = /^\d{9}-\d{4}$/;
  return re.test(val);
}

function isValidEmail(val) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-/\d]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
}

function isValidTelephone(val) {
  if (val !== null && val !== undefined) {
    const re = /^\([\d]{3}\)[\d]{3}-[\d]{4}$/;
    return re.test(val.replace(/\s+/g, ''));
  } else {
    return false;
  }
}

function isValidDollarAmount(val) {
  return val > 0;
}

export function isValidDate(val) {
  // Using parse as a workaround for JavaScript's inbuilt correcting of dates
  // e.g. 30th February, 2020 is converted to Sun Mar 01 2020
  // See https://www.sitepoint.com/date-fns-javascript-date-library/
  const parseDate = parse(val, 'MM/dd/yyyy', new Date());

  if (isValid(parseDate)) {
    // Check that date is not more than a year in the past or more than 2 years in the future
    const today = new Date();
    const oneYearBeforeToday = subYears(today, 1);
    const twoYearsTime = addYears(today, 2);

    if (
      isBefore(parseDate, oneYearBeforeToday) ||
      isAfter(parseDate, twoYearsTime)
    ) {
      return false;
    }
  } else {
    return false;
  }
  return true;
}

const isValidFederalTaxIdOrEmpty = (val) => {
  const re = /^(\d{2}-\d{7}){0,1}$/;
  return re.test(val);
};

function getCachedField(name) {
  const value = localStorage.getItem(name);
  return value ? value : '';
}

export function isValidZip(val) {
  const re = /^\d{5}$/;
  return re.test(val);
}

const cachedFields = [
  'shopName',
  'shopAddress1',
  'shopAddress2',
  'shopState',
  'shopZipCode',
  'shopEmail',
  'shopTelephone',
  'shopContact'
];

export default class ShopDetailsStore {
  data = {
    claimNumber: {
      value: '         -    ',
      error: false,
      valid: (value) => isValidClaimNumber(value)
    },
    dollarAmount: {
      value: '',
      error: false,
      valid: (value) => isValidDollarAmount(value)
    },
    estimatedCompletionDate: {
      value: '',
      error: false,
      valid: (value) => isValidDate(value)
    },
    directionToPay: {
      value: '',
      error: false,
      valid: (value) => this.isOptionSelected(value)
    },
    shopName: {
      value: getCachedField('shopName'),
      error: false,
      valid: (value) => isMandatory(value)
    },
    shopAddress1: {
      value: getCachedField('shopAddress1'),
      error: false,
      valid: (value) => isMandatory(value)
    },
    shopAddress2: {
      value: getCachedField('shopAddress2'),
      error: false,
      valid: (value) => isMandatory(value)
    },
    shopState: {
      value: getCachedField('shopState'),
      error: false,
      valid: (value) => isMandatory(value)
    },
    shopZipCode: {
      value: getCachedField('shopZipCode'),
      error: false,
      valid: (value) => isValidZip(value)
    },
    shopEmail: {
      value: getCachedField('shopEmail'),
      error: false,
      valid: (value) => isValidEmail(value)
    },
    shopTelephone: {
      value: getCachedField('shopTelephone'),
      error: false,
      valid: (value) => isValidTelephone(value)
    },
    shopContact: {
      value: getCachedField('shopContact'),
      error: false,
      valid: (value) => isMandatory(value)
    },
    taxId: {
      value: '',
      error: false,
      valid: (value) => isValidFederalTaxIdOrEmpty(value)
    }
  };

  error = false;

  init() {
    this.error = false;
    Object.keys(this.data).forEach((key) => {
      const field = this.data[key];
      field.error = false;
      if (!cachedFields.includes(key)) {
        field.value = '';
      }
    });
  }

  isOptionSelected(val) {
    if (val === null || val === undefined || val.length <= 0) {
      this.data.directionToPay.value = DIRECTION_TO_PAY_OPTIONS[1].value;
    }
    return true;
  }

  set(name, value) {
    const field = this.data[name];
    field.value = value;
    if (field.valid) {
      field.error = !field.valid(value);
    }
    if (!field.error) {
      if (cachedFields.includes(name)) {
        localStorage.setItem(name, value);
      }
    }
    this.error = false;
  }

  validate() {
    this.error = false;
    Object.keys(this.data).forEach((key) => {
      const field = this.data[key];
      if (field.valid) {
        field.error = !field.valid(field.value);
        this.error |= field.error;
      }
    });
    return this.error;
  }

  setErrorMsg(dataType) {
    if (this.data[dataType].error) {
      const errorMessages = [];
      errorMessages.push({
        text: ERROR_LIST.shopDetails[dataType],
        type: 'error'
      });
      return errorMessages;
    } else {
      return undefined;
    }
  }
}

decorate(ShopDetailsStore, {
  data: observable,
  error: observable,
  init: action,
  set: action,
  validate: action
});
